
.loginMenu{
    width: 360px;
    padding: 8% 0 0;
    margin: auto;
  }
 
  .form {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    max-width: 360px;
    margin: 0 auto 100px;
    padding: 45px;
    text-align: center;
    border-radius: 2rem;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  }
  .form input {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
  }
  .form button {
    text-transform: uppercase;
    outline: 0;
    background: #696969;
    width: 100%;
    border: 0;
    padding: 15px;
    color: #FFFFFF;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
  }
  .form button:hover,
  .form button:active,
  .form button:focus {
    background: #595959;
    transition: 0.5s;
  }
  .form .message {
    margin: 15px 0 0;
    color: #b3b3b3;
    font-size: 12px;
  }
  .form .message a {
    color: #696969;
    text-decoration: none;
  }
  .form .registerForm {
    display: none;
  } 
  /*  */
  .login-page {
    width: 23em;
    padding: 1em auto;
    margin: auto;
  }
 
  .form .logo-airmee{
    width:100%;
    margin:0 0 2em;
  }
  .form input {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 1.1em;
    padding: 1em;
    box-sizing: border-box;
    font-size: 0.9em;
  }
  .form button {
    outline: 0;
    text-transform: uppercase;
    font-weight:700;
    background: #17bed2;
    width: 100%;
    border: 0;
    padding: 1em;
    color: #FFFFFF;
    font-size: 0.9em;
    transition: all 0.3 ease;
    cursor: pointer;
  }
  .form button:hover,.form button:active,.form button:focus {
    background: #07394F;
  }