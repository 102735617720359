.card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    transition: height 0.3s ease; 
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .card.expanded {
    white-space: normal;
    height: auto; 
  }
  
  .card-list {
    list-style: none;
    padding: 0;
  }
  